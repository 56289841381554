import { Row } from "./Row"
import mods from './mods'
import { ModRow } from "./ModRow"
export function App() {
    return (<div className="container text-center">
              <Row className="m-2">
                  <h1 className=""> Wackery's Knightfall Mods </h1>
                </Row>
                <Row>
                  <p>
                    A repository for all of my Knightfall mods.
                  </p>
                </Row>
                {mods.map((mod) => {
                  return <Row><ModRow mod={mod} key={mod.name} /></Row>
                })}
            </div>)
}