import { PropsWithChildren } from "react";

export function Row(props: PropsWithChildren & { className?: string } ) {
    return (
        <div className={ "row" + " " + (props.className || "") }>
            <div className="col" />
            <div className="col-auto">
                { props.children }
            </div>
            <div className="col" />
        </div>
    )
}