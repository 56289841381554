export type ChangeLog = {
    version: string,
    changes: string[]
}
export type Mod = {
    name: string,
    description: string,
    version: string,
    dl: boolean | string,
    documentation?: string;
    changelog: ChangeLog[],
    hidden: boolean;
}
const mods: Mod[] = [
    {
        "name": "CustomColorFramework",
        "description": "Add custom colors to your knight with this handy framework! Includes 3 custom colors!",
        "version": "1.0.0",
        "dl": false,
        "documentation": "Use 'CCF.CustomColors.AddColor(UnityEngine.Color)' during your OnApplicationStart() function",
        "changelog": [
            {
                "version": "1.0.0",
                "changes": ["Initial release"]
            }
        ],
        "hidden": true
    },
    
    {
        "name": "WKFAPI",
        "description": "(Indev) A modding api for knightfall to make it easier to mod the game.",
        "version": "0.0.0",
        "dl": false,
        "documentation": "See <a href=\"https://wkfapi.wackery.com\">wkfapi.wackery.com</a> for documentation",
        "changelog": [
        ],
        "hidden": false
    }
]
export default mods
