import { Mod } from "./mods"
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Accordian from 'react-bootstrap/Accordion'
type Props = {
    mod: Mod
}

export function ModRow(props: Props) {
  if (props.mod.hidden) {
    return <></>
  }
    let dlButton = <Button variant="primary" onClick={ () => {function downloadURI(uri: string, name: string) {var link = document.createElement("a"); link.setAttribute('download', name); link.href = uri; document.body.appendChild(link); link.click(); link.remove(); }; downloadURI("/mods/"+props.mod.dl, ""+props.mod.dl)}}>Download</Button>
    if (!props.mod.dl) {
        dlButton = <Button variant="secondary" disabled>Unavailable</Button>
    }

    return (<Card style={{"width": "30rem"}}>
    <Card.Body>
      <Card.Title>{props.mod.name}</Card.Title>
      <Card.Subtitle className="mb-2">Latest Version: {props.mod.version} </Card.Subtitle>
      <Card.Text>{props.mod.description}</Card.Text>
      { dlButton }
      <Accordian className="mt-2">
        <Accordian.Item eventKey={props.mod.name+"documentation"}>
          <Accordian.Header>Documentation</Accordian.Header>
          <Accordian.Body><span dangerouslySetInnerHTML={ { __html: props.mod.documentation || "Nothing seems to be here..." } }></span></Accordian.Body>
        </Accordian.Item>
        <Accordian.Item eventKey={props.mod.name+"changelog"}>
          <Accordian.Header>
            Changelog
          </Accordian.Header>
          <Accordian.Body>
            {props.mod.changelog.map((c) => {
              return (<div>
                <strong>{c.version}</strong><br />
                <ul style={{"listStylePosition": "inside"}}>
                  {c.changes.map((r) => <li key={r}>{r}</li> )}
                </ul>
              </div>);
            }).reverse()}
          </Accordian.Body>
        </Accordian.Item>
      </Accordian>
    </Card.Body>
  </Card>)
}